// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b1_kw";
export var caseStudyBackground__lineColor = "b1_kt";
export var caseStudyHead__imageWrapper = "b1_kp";
export var caseStudyProjectsSection = "b1_kx";
export var caseStudyQuote__bgQuote = "b1_nf";
export var caseStudyQuote__bgRing = "b1_kr";
export var caseStudyVideo__ring = "b1_kG";
export var caseStudy__bgDark = "b1_km";
export var caseStudy__bgLight = "b1_kl";
export var caseStudy__bgLightGray = "b1_mR";